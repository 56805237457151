import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import ButtonLink from './ButtonLink'
import bg from '../images/join-bg.png'
import Wysiwyg from './Wysiwyg'
import Title from './Title'

const Wrapper = styled.section`
  margin-bottom: -56px;
  padding-bottom: 144px;
  background: url(${bg}) left bottom no-repeat;
  background-size: 150%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: -128px;
    background-position: center bottom;
    background-size: contain;
  }
`
const Heading = styled(Title)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.primary};
`
const ButtonWrapper = styled.section`
  margin: 32px 0;
`

const JoinTeam = ({ joinTitle, joinBody, joinCtaLabel, joinCta }) => {
  return (
    <Wrapper>
      <Row>
        <Col xs={8} xsOffset={1} md={5} mdOffset={3} lg={4} lgOffset={4}>
          <Heading tag="h2" content={joinTitle} />
          <Wysiwyg content={joinBody} />
          <ButtonWrapper>
            <ButtonLink {...joinCta} target="_blank">
              {joinCtaLabel}
            </ButtonLink>
          </ButtonWrapper>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default JoinTeam
