import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Certification from '../components/Certification'

// data
// ==========================================================================================
const query = graphql`
  query CertificationQuery {
    prismic {
      data: certification(uid: "certification", lang: "en-gb") {
        title: certification_title
        intro: certification_intro
        body: certification_body
        certificates {
          logo
        }
      }
    }
  }
`

// static query
// ==========================================================================================
const CertificationWithQuery = props => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={process.env.GATSBY_ENV === 'development' ? query.toString() : query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <Certification {...data} {...props} />
      ),
      query
    )}
  />
)

export default CertificationWithQuery
