import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Title from './Title'
import Image from './Image'
import { ReactComponent as LinkedinLogo } from '../images/linkedin-logo.svg'

// styles
// ==========================================================================================
const Header = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 48px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 80px;
  }
`
const Heading = styled(Title)`
  margin-bottom: 8px;
`
const Person = styled.div`
  position: relative;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  margin-bottom: 24px;
  overflow-wrap: break-word;
  hyphens: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block !important;
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 224px;
  margin-bottom: 8px;
  background: ${({ theme }) => theme.colors.gray10};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 24px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    /* margin-bottom: 72px; */
  }
  img {
    /* position: absolute; */
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
const Role = styled.div`
  margin-bottom: 8px;
`
const SocialLink = styled.a`
  background-image: none;
`

// component
// ==========================================================================================
const TeamList = ({ teamTitle, teamIntro, team, showAll }) => {
  return (
    <Row>
      <Col xs={7} xsOffset={1} md={4}>
        <Header>
          <Heading tag="h2" content={teamTitle} />
          {teamIntro}
        </Header>
      </Col>
      {team.map(({ name, role, portrait, socialLink }, i) => {
        return (
          <Col
            xs={4}
            xsOffset={i % 2 === 0 ? 1 : 0}
            md={2}
            mdOffset={(i + 2) % 4 === 0 ? 1 : 0}
            lg={2}
            key={name}
          >
            <Person isHidden={!showAll && i >= 2}>
              <ImageWrapper>
                <Image image={portrait} />
              </ImageWrapper>
              <h5>{name}</h5>
              <Role>{role}</Role>
              {socialLink && (
                <SocialLink
                  href={socialLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn profile"
                >
                  <LinkedinLogo />
                </SocialLink>
              )}
            </Person>
          </Col>
        )
      })}
    </Row>
  )
}

export default TeamList
