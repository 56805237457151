import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Image from './Image'

// styles
// ==========================================================================================
const ImageWrapper = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  width: 75%;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-bottom: 75%;

  img {
    position: absolute;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-bottom: 50%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block !important;
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
    padding-bottom: 35%;
  }
`

// component
// ==========================================================================================
const CertificateList = ({ certificates, showAll }) => {
  return (
    <Row>
      {certificates.map(({ logo }, i) => {
        const xsOffset = i % 2 === 0 ? 1 : 0
        const mdOffset = i % 3 === 0 ? 1 : 0
        return (
          <Col xs={4} md={2} {...{ xsOffset, mdOffset }} key={logo.url}>
            <ImageWrapper isHidden={!showAll && i >= 2}>
              <Image image={logo} />
            </ImageWrapper>
          </Col>
        )
      })}
    </Row>
  )
}

export default CertificateList
