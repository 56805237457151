import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Title from './Title'
import Wysiwyg from './Wysiwyg'
import CertificateList from './CertificateList'
import ShowAllMobile from './ShowAllMobile'

// styles
// ==========================================================================================
const Header = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 48px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 80px;
  }
`
const CertificatesWrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 0;
  }
`

// component
// ==========================================================================================
const Certification = ({ title, intro, body, certificates }) => {
  return (
    <section>
      <Row>
        <Col xs={7} xsOffset={1}>
          <Header>
            <Title tag="h2" content={title} />
            <p>{intro}</p>
          </Header>
        </Col>
      </Row>
      <CertificatesWrapper>
        <ShowAllMobile>
          <CertificateList certificates={certificates} />
        </ShowAllMobile>
      </CertificatesWrapper>
      <Row>
        <Col xs={7} xsOffset={1}>
          <Wysiwyg content={body} />
        </Col>
      </Row>
    </section>
  )
}

export default Certification
