import React from 'react'

import ShowAllMobile from './ShowAllMobile'
import TeamList from './TeamList'

// component
// ==========================================================================================
const Team = props => {
  return (
    <section>
      <ShowAllMobile>
        <TeamList {...props} />
      </ShowAllMobile>
    </section>
  )
}

export default Team
